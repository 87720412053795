
import { AlertOptions, alertController, loadingController, toastController } from '@ionic/core';

export function playTune(tag) {
  const audio =  document.querySelector('audio#'+tag+'') as HTMLAudioElement;
   audio.play();
}

export function isValidNumber(number, reg) {
  console.log('validating', number);
  if (number) {
    number = number.toString();
    if (number && number.length > 8) {
    //  const code = escape(country_info.calling_code);
      //const i = '^\[0-9]{8,}$';/^6[0-9]{8}/
      const regex = new RegExp((reg), 'i');

      if (regex.test(number)) return true;
    }
  }
}

export function isValidPrice(number, max, min) {
  if (number && number <= max && number >= min)
  {
    number = number.toString();
    const regex = /^[1-9]([0-9]{0,8})$/i;
    if (regex.test(number)) {return true;}
  }
}

//export sha256 digest in hex using web crypto api
export async function sha256(message) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export async function showToast(t) {
  const toast = await toastController.create({
    message: t,
    duration: 1000
  });
  await toast.present();
}

  export async function presentLoadingWithOptions() {

    
    const l = await loadingController.create({
      spinner: 'lines',
      duration: 17000,
      translucent: true,
      cssClass: 'custom-loading'
    })

    await l.present();
  }

  export async function dismissLoading() {
    setTimeout(()=> {
    loadingController.dismiss();
  },200);
    console.log('dimiss');
  }


  export async function dismissAlert() {

    if (alertController)  await alertController.dismiss();
  }

  export async function showAlert(options) {

    const alert = await alertController.create(options);
      playTune('info');
      await alert.present();
  }


  export async function showAlertWithSound(sound:string, options: AlertOptions) {

    const alert = await alertController.create(options);
      await alert.present();
      playTune(sound);
  }

  export async function showSimpleAlert(header, message) {
    const alert = await alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    playTune('info_alert');

    await alert.present();
  }

